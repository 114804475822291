<template>
    <div class="settings">
        <v-alert type="error" class="settings-alert elevation-3" :value="errorSave" :dismissible="true">
            {{ $t('settings.error_save_settings') }}
        </v-alert>

        <v-row style="max-width: 1600px" class="mx-auto">
            <v-col sm="12" md="6">
                <v-card align="center" justify="center" class="settings-card mx-auto elevation-6 pb-4">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>

                        <v-toolbar-title class="overline">
                            Адреса партнеров
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <!-- <v-btn icon @click="onClickSave" :disabled="!saveTimer">
                            <v-icon>mdi-content-save-outline</v-icon>
                        </v-btn> -->

                    </v-toolbar>

                    <v-row class="settings-content">

                        <v-col cols="12">
                            <p class="my-0 mt-4">Обновить координаты всех партнеров:</p>
                        </v-col>

                        <v-btn :disabled="updateCoordinatesFlag" :loading="updateCoordinatesFlag" color="success" @click="onClickUpdateCoordinates">
                            <v-icon>mdi-refresh</v-icon> Обновить
                        </v-btn>

                        <v-col cols="12" v-if="updateCoordinatesFlag">
                            <p class="my-0 text-wrap">
                                <v-icon color=warning class="mr-1">mdi-information-outline</v-icon>
                                Не закрывайте окно до завершения обновления.
                            </p>
                            <p class="my-3">
                                <v-progress-linear :value="(partnerUpdateStep / partners.length)*100"></v-progress-linear>
                            </p>
                        </v-col>

                        
                        <!-- <v-btn class="ml-4" dark color="success" @click="onClickUpdateCoordinates">
                            <v-icon>mdi-update</v-icon>
                            Обновить
                        </v-btn> -->


                    </v-row>

                </v-card>
            </v-col>

            <v-col cols="12">
                <v-card align="center" justify="center" class="settings-card mx-auto elevation-6 pb-4">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>

                        <v-toolbar-title class="overline">
                            {{ $t('settings.prices') }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="onClickSave" :disabled="!saveTimer">
                            <v-icon>mdi-content-save-outline</v-icon>
                        </v-btn>

                    </v-toolbar>

                    <v-row class="settings-content">

                        <v-col cols="12">
                            <p class="my-0 mt-4">1. Цена доставки по геозонам (переместите Партнера в соответствующий ценовой блок):</p>
                        </v-col>
                        
                        <v-col cols="12" v-if="newPartners.length">
                            <v-card class="mx-auto" max-width="500">
                                <v-toolbar color="teal" dark height="40">
                                    <v-toolbar-title>Нераспределенные партнеры ({{ newPartners.length }})</v-toolbar-title>
                                </v-toolbar>

                                <v-list dense>
                                    <draggable class="list-group" :list="newPartners" group="people" @change="onChangePrice">
                                        <v-list-item v-for="partner in newPartners" :key="'new-'+partner.id" @click="onClickAddress(partner.address)">
                                            <v-list-item-content class="d-block">
                                                <b>{{partner.name}}</b> {{' - ' + partner.address}}
                                            </v-list-item-content>
                                        </v-list-item>
                                    </draggable>
                                </v-list>
                            </v-card>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="3" v-for="(price, i) of settings.prices" :key="i">
                            <v-card class="mx-auto" max-width="500">

                                <v-toolbar color="teal" dark height="40">
                                    <v-toolbar-title>Цена {{ i + 1 }} ({{ price.length }})</v-toolbar-title>
                                </v-toolbar>

                                <v-list dense>
                                    <draggable class="list-group" :list="price" group="people" @change="onChangePrice">
                                        <v-list-item v-for="(partner, j) in price" :key="i+'-'+j+'-'+partner.id" @click="onClickAddress(partner.address)">
                                            <v-list-item-content class="d-block">
                                                <b>{{partner.name}}</b> {{' - ' + partner.address}}
                                            </v-list-item-content>
                                        </v-list-item>
                                    </draggable>
                                </v-list>

                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <p class="my-0 mt-4">2. Цена доставки, если адрес доставки не соответствует ни одной геозоне:</p>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="3">
                            <v-text-field class="py-0" v-model="settings.price_default" label="Цена, руб." @change="onChangePriceDefault()"></v-text-field>
                        </v-col>

                        <v-col cols="12" class="settings-test">
                            <p class="my-0 mt-4">3. Тест цены доставки в зависимости от адреса:</p>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="3" class="settings-test">
                            <div class="d-flex flex-row">
                                <v-text-field class="py-0" v-model="address" label="Адрес доставки" v-on:keyup.enter="onClickPriceGet" @change="onChangeAddress()"></v-text-field>
                                <v-btn class="ml-4" icon @click="onClickPriceGet">
                                    <v-icon>mdi-arrow-right</v-icon>
                                </v-btn>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="9" class="settings-test">
                            <p class="mt-2 text-caption">{{test_address_result}}</p>
                        </v-col>

                    </v-row>

                </v-card>
            </v-col>

            <!-- <v-col cols="12">
                <v-card align="center" justify="center" class="settings-card mx-auto elevation-6">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ $t('settings.common') }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="onClickSave">
                            <v-icon>mdi-content-save-outline</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-row class="settings-content">

                    </v-row>

                </v-card>
            </v-col> -->

        </v-row>
        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    import config from '../../config'
    import draggable from 'vuedraggable'
    import moment from 'moment-timezone'

    moment.locale('ru')
    moment.tz.setDefault(config.timezoneString)

    export default {
        name: 'two-lists',
        display: 'Two Lists',
        order: 1,
        components: {
            draggable,
        },
        data() {
            return {
                test_address_result: '',
                updateCoordinatesFlag: false,
                errorSave: false,
                needSave: false,
                saveTimer: 0,
                partners: [],
                address: '',
                settings: {
                    prices: [
                        [],[],[],[]
                    ]
                },
                price_default: 250,
                newPartners: [],
                partnerUpdateStep: 0,
            }
        },
        mounted() {
            console.log('start!')
            // this.updateData()
            this.init()
        },
        created() {
        },

        beforeDestroy() {},

        destroyed() {
            return this.timer ? clearInterval(this.timer) : false
        },
        watch: {},
        computed: {},
        methods: {
            async onClickUpdateCoordinates() {
                this.updateCoordinatesFlag = true
                this.partnerUpdateStep = 0
                for (let partner of this.partners) {
                    this.partnerUpdateStep++
                    let res = await this.$store.dispatch('partner_update_coordinates', partner.id)
                    if (res.data.success) {
                        //
                    }
                }
                this.updateCoordinatesFlag = false
                //     if (res.data.success) {
                //         //
                //     }
                //     this.updateCoordinatesFlag = false
                // })
            },
            onSelectAddress() {
                console.log('onSelectAddress')
                // this.address = address
            },
            onClickAddress(address) {
                console.log('onAddressClick', address)
                this.address = address
            },
            onClickPriceGet() {
                this.test_address_result = 'поиск...'
                this.$store
                    .dispatch('address_to_geofence', this.address)
                    .then(res => {

                        // console.log('res.data', res.data)

                        if (res.data.success) {
                            const geofence = res.data.geofence
                            console.log('geofence', geofence)

                            if (!geofence.name) {
                                geofence.name = 'Без имени'
                            }

                            this.test_address_result = `Геозона: ${geofence.name}`

                            let i = 0
                            for (let price of geofence.price) {
                                geofence.price[i] = price ? price : '-'
                                this.test_address_result += `, Цена ${i + 1}: ${price}`
                                i++

                            }

                            // this.test_address_result = `Геозона: ${geofence.name}, Цена 1: ${geofence.price[0]}, Цена 2: ${geofence.price[1]}, Цена 3: ${geofence.price[2]}, Цена 4: ${geofence.price[3]}`

                        } else {

                            this.test_address_result = `Геозона не найдена, Цена: ${this.settings.price_default}`
                        }


                    })
                    .catch(err => {
                        // this.loading = false
                        console.log(err)
                        // this.$store.dispatch('logout').then(() => this.$router.push('/login'))
                    })

            },
            init() {
                console.log('Settings init!')


                this.timer = setInterval(() => {
                    if (this.saveTimer) {
                        this.saveTimer--
                        if (this.saveTimer == 0) {
                            this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                            this.$store
                                .dispatch('settings_set', this.settings)
                                .then(res => {
                                    if (!res.data.success) {
                                        console.log('settings saved fail')
                                        this.errorSave = true
                                        this.saveTimer = config.geofence.savetime
                                    } else {
                                        console.log('settings saved ok')
                                        this.errorSave = false
                                        this.$store.commit('settings', this.settings)
                                    }
                                })
                                .catch(err => {
                                    this.errorSave = true
                                    this.saveTimer = config.geofence.savetime
                                    console.log(err)
                                })

                        }
                    }
                    
                }, 100)

                this.settings = this.$store.state.settings

                this.$store
                    .dispatch('settings_get')
                    .then(res => {
                        if (res.data.success) {


                            const settings = res.data.settings

                            this.$store
                                .dispatch('partners_get')
                                .then(res => {
                                    if (res.data.success) {
                                        console.log('settings7')



                                        if (settings && settings.prices) {

                                            console.log('settings1')

                                            if (this.settings && this.settings.prices) {
                                                console.log('settings2')

                                                const date_server_is_last = settings.date_update || moment(settings.date_update).isSameOrAfter(this.settings.date_update)
                                                if (date_server_is_last) {
                                                    console.log('settings3')
                                                    this.settings = settings
                                                    this.$store.commit('settings', this.settings)

                                                } else {
                                                    console.log('settings4')
                                                    this.saveTimer = config.geofence.savetime
                                                }
                                            } else {
                                                console.log('settings5')
                                                this.settings = settings
                                                this.$store.commit('settings', this.settings)
                                            }
                                            for (let price of this.settings.prices) {
                                                price.sort(this.sortPartners)
                                            }
                                            let partners = res.data.partners
                                            this.partners = res.data.partners
                                            let change = false

                                            // фильтр несуществующих партнеров
                                            for (let i = 0; i < this.settings.prices.length; i++) {
                                                this.settings.prices[i] = this.settings.prices[i].filter(price_partner => {
                                                    for (let partner of partners) {
                                                        if (price_partner.id == partner.id) {
                                                            return true
                                                        }
                                                    }
                                                    change = true
                                                    return false
                                                })
                                            }
                                            // фильтр дублирующихся партнеров
                                            for (let i = 0; i < this.settings.prices.length; i++) {
                                                let partners_set = new Set()
                                                for (let partner of this.settings.prices[i]) {
                                                    partners_set.add(partner.id)
                                                }
                                                this.settings.prices[i] = this.settings.prices[i].filter(partner => partners_set.has(partner.id) && partners_set.delete(partner.id))
                                            }

                                            let prices = []
                                            if (this.settings.prices.length == config.geofence.price.length) {
                                                prices = [...this.settings.prices[0], ...this.settings.prices[1], ...this.settings.prices[2], ...this.settings.prices[3]]
                                            } else {
                                                for (let price of config.geofence.price) {
                                                    prices.push([])
                                                }
                                                this.settings.prices = prices
                                                change = true
                                            }
                                            console.log('prices', prices)
                                            console.log('res.data.partners', res.data.partners)
                                            // console.log('res.data.partners', res.data.partners)
                                            let partners_new = res.data.partners.filter(x => {
                                                for (let price of prices) {
                                                    
                                                    if (x.id == price.id) {
                                                        // console.log('x.id', price.id)
                                                        return false
                                                    }
                                                }
                                                return true
                                            })
                                            console.log('partners_new', partners_new)
                                            this.newPartners = partners_new.sort(this.sortPartners)

                                            if (change) {
                                                this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                                                this.$store.commit('settings', this.settings)
                                                this.saveTimer = config.geofence.savetime
                                            }

                                            // this.newPartners = res.data.partners.filter(x => !prices.includes(x)).concat(prices.filter(x => !prices.includes(x)))

                                        } else {
                                            console.log('settings6')
                                            this.partners = res.data.partners

                                            this.settings.prices = []
                                            for (let price of config.geofence.price) {
                                                this.settings.prices.push([])
                                            }
                                            this.newPartners = this.partners.sort(this.sortPartners)
                                            this.settings.price_default = this.price_default
                                            this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')

                                            this.$store.commit('settings', this.settings)
                                            this.saveTimer = config.geofence.savetime
                                        }

                                    }

                                })
                                .catch(err => {
                                    console.log(err)
                                })

                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })

            },
            onChangePrice: function(evt) {
                console.log(evt)
                this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                this.$store.commit('settings', this.settings)
                this.saveTimer = config.geofence.savetime
            },
            onChangePriceDefault() {
                console.log('onChangePriceDefault')

                this.settings.date_update = moment().format('YYYY-MM-DD HH:mm:ss')
                this.$store.commit('settings', this.settings)
                this.saveTimer = config.geofence.savetime
            },
            onClickSave() {
                console.log('onClickSave')
                this.saveTimer = 1
            },
            onChangeAddress() {
                console.log('onChangeAddress')
            },
            sortPartners(a, b) {
                if (a.name > b.name) {
                    return 1
                }
                if (a.name < b.name) {
                    return -1
                }
                if (a.address > b.address) {
                    return 1
                }
                if (a.address < b.address) {
                    return -1
                }
                return 0
            },
        },
    }
</script>

<style lang="stylus">

    .settings
        height 100%

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                .v-data-table__expanded__row:hover
                    background #e4e4e4 !important
                    cursor pointer !important

                td
                    font-size 0.75rem
                    padding 0
                    padding-left 16px
                    p
                        margin-top 6px
                        margin-bottom 6px



        .v-list
            overflow-y auto
            height 216px
            .list-group
                height 100%
                .v-list-item
                    cursor pointer
                    &:hover
                        background-color beige
                .sortable-ghost
                    background-color beige

        .expanded-content
            box-shadow inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75)
            background none !important
            &:hover
                cursor default !important

        .th-first-item
            width 30%


        .v-alert
            width 450px
            position fixed
            display flex
            top 10px
            margin 0 auto
            z-index 10


    .settings-content
        padding-left 40px
        padding-right 40px
        text-align left

    .settings-test
        background-color #feffe4




</style>
